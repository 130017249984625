import React from "react";
import {useTranslation} from "react-i18next";

//import Slider from "react-slick";

const Pricing = ({classicHeader, darkTheme}) => {
    const {t} = useTranslation();
    const reviews = [
        /*{
            name: "",
            title: "",
            price: "",
            src: "",
            desc: "",
            rating: 0,
            checks: []
        },*/
        {
            name: t("base.titre"),
            title: t("base.description"),
            price: t("base.prix"),
            src: "",
            desc: "Only trying it out since a few days. But up to now excellent. Seems to work flawlessly. priced simply dummy text of the printing and typesetting industry.",
            rating: 5,
            checks: [1, 3, 5, 6]
        },
        /*{
            name: "",
            title: "",
            price: "",
            src: "",
            desc: "",
            rating: 0,
        },*/
        {
            name: t("vue_3600.titre"),
            title: t("vue_3600.description"),
            price: t("vue_3600.prix"),
            src: "",
            desc: "Easy to use, reasonably priced simply dummy text of the printing and typesetting industry. Quidam lisque persius interesset his et, in quot quidam possim iriure.",
            rating: 5,
            checks: [0, 1, 3, 5, 6]
        },
        /*{
            name: "",
            title: "",
            price: "",
            src: "",
            desc: "",
            rating: 0,
        },*/
        {
            name: t("b2b.titre"),
            title: t("b2b.description"),
            price: t("b2b.prix"),
            src: "",
            desc: "I am happy Working with printing and typesetting industry. Quidam lisque persius interesset his et, in quot quidam persequeris essent possim iriure.",
            rating: 5,
            checks: [1, 3, 4, 5, 6]
        },
        /*{
            name: "",
            title: "",
            price: "",
            src: "",
            desc: "",
            rating: 0,
        },*/
        {
            name: t("integration.titre"),
            title: t("integration.description"),
            price: t("integration.prix"),
            src: "",
            desc: "I have used them twice now. Good rates, very efficient service and priced simply dummy text of the printing and typesetting industry quidam interesset his et. Excellent.",
            rating: 5,
            checks: [1, 2, 3, 5, 6, 7, 8]
        },
        /*{
            name: "",
            title: "",
            price: "",
            src: "",
            desc: "",
            rating: 0,
        },*/
        {
            name: t("complet.titre"),
            title: t("complet.description"),
            price: t("complet.prix"),
            src: "",
            desc: "I have used them twice now. Good rates, very efficient service and priced simply dummy text of the printing and typesetting industry quidam interesset his et. Excellent.",
            rating: 5,
            checks: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
        },
    ];

    /*const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    };*/
    return (
        <section
            id="pricing"
            className={"section " + (darkTheme ? "bg-dark-1" : "")}
        >
            <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
                {/* Heading */}
                <div className="position-relative d-flex text-center mb-5">
                    <h2
                        className={
                            "text-24  text-uppercase fw-600 w-100 mb-0 " +
                            (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
                        }
                    >
                        {t("menu_pricing")}
                    </h2>
                    <p
                        className={
                            "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                            (darkTheme ? "text-white" : "text-dark")
                        }
                    >
                        {" "}
                        {t("abonnements_et_tarifs")}
                        <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto"/>
                    </p>
                </div>
                {/* Heading end*/}
                <div className="d-flex flex-column flex-xl-row align-items-stretch">
                    {/*<Slider {...settings}>*/}
                    {reviews.length > 0 &&
                        reviews.map((value, index) => (
                            <div key={index} className="mx-2 my-2">
                                <div
                                    className={
                                        "rounded p-2 " + (darkTheme && value.rating > 0 ? "bg-dark" : "bg-dark-2")
                                    }
                                >
                                    <div
                                        className="d-flex align-items-center mt-auto mb-2 pb-2 border-bottom border-secondary">
                                        {/*<img
                                            className="img-fluid rounded-circle border d-inline-block w-auto"
                                            src={value.src}
                                            alt=""
                                        />*/}
                                        <p className="ms-3 mb-0 text-center w-100">
                                            <strong
                                                className={
                                                    "d-block fw-600 text-6 " +
                                                    (darkTheme ? "text-primary" : "text-primary")
                                                }
                                            >
                                                {value.name}&nbsp;
                                            </strong>
                                            <span className="text-muted fw-500">
                                                {" "}
                                                {value.title}{" "}&nbsp;
                                                </span>
                                            <strong
                                                className={
                                                    "d-block fw-600 text-4 " +
                                                    (darkTheme ? "text-info" : "text-info")
                                                }
                                            >
                                                {value.price}&nbsp;
                                            </strong>
                                            {value.rating > 0 ? (<span className="text-2 text-muted">{t("base.tenant_mois")}
                                                </span>) : (<span>&nbsp;</span>)}
                                            <br/>
                                            {value.rating > 0 ? (<span className="text-1 text-muted">
                                                {t("base.notes")}<sup>1</sup>
                                                </span>) : (<span>&nbsp;</span>)}
                                            <br/>
                                            {value.rating > 0 ? (<span className="text-2 text-muted">
                                                {t("base.taxes")}</span>) : (<span>&nbsp;</span>)}

                                        </p>
                                    </div>
                                    {/*<p
                                        className={
                                            " fw-500 mb-4 " + (darkTheme ? "text-white" : "text-dark")
                                        }
                                    >
                                        “{value.desc}”
                                    </p>*/}
                                    {/*<span className="text-2">
                                        {[...Array(value.rating)].map((value, i) => (
                                        <i className="fas fa-star text-warning" key={i}/>
                                        ))}
                                    </span>*/}
                                    <div className="text-2 d-flex flex-column pt-2">
                                        <div className="d-flex flex-row">
                                            <div className="pt-1"><span className="text-info">
                                                        {value.checks.indexOf(0) !== -1 ? (
                                                            <i className="fa-solid fa-check"></i>) : (
                                                            <span>&nbsp;&nbsp;&nbsp;</span>)}
                                                        </span></div>
                                            <div className="mx-1">
                                                <p style={{border: '0px solid gray'}}
                                                   className={"p-0 " + (value.checks.indexOf(0) !== -1 ? "text-white" : "text-white-50")}>
                                                    {t("base.fonctionnalites.naviguer_donnees")}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <div className="pt-1"><span
                                                className="text-info">{value.checks.indexOf(1) !== -1 ? (
                                                <i className="fa-solid fa-check"></i>) : (
                                                <span>&nbsp;&nbsp;&nbsp;</span>)}</span></div>
                                            <div className="mx-1"><p style={{border: '0px solid gray'}}
                                                                     className={"p-0 " + (value.checks.indexOf(1) !== -1 ? "text-white" : "text-white-50")}>

                                                {t("base.fonctionnalites.acces_api_synchrone")}
                                            </p></div>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <div className="pt-1"><span
                                                className="text-info">{value.checks.indexOf(2) !== -1 ? (
                                                <i className="fa-solid fa-check"></i>) : (
                                                <span>&nbsp;&nbsp;&nbsp;</span>)}</span></div>
                                            <div className="mx-1"><p style={{border: '0px solid gray'}}
                                                                     className={"p-0 " + (value.checks.indexOf(2) !== -1 ? "text-white" : "text-white-50")}>

                                                {t("base.fonctionnalites.acces_api_asynchrone")}
                                            </p></div>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <div className="pt-1"><span
                                                className="text-info">{value.checks.indexOf(3) !== -1 ? (
                                                <i className="fa-solid fa-check"></i>) : (
                                                <span>&nbsp;&nbsp;&nbsp;</span>)}</span></div>
                                            <div className="mx-1"><p style={{border: '0px solid gray'}}
                                                                     className={"p-0 " + (value.checks.indexOf(3) !== -1 ? "text-white" : "text-white-50")}>

                                                {t("base.fonctionnalites.chargement_donnees")}
                                            </p></div>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <div className="pt-1"><span
                                                className="text-info">{value.checks.indexOf(4) !== -1 ? (
                                                <i className="fa-solid fa-check"></i>) : (
                                                <span>&nbsp;&nbsp;&nbsp;</span>)}</span></div>
                                            <div className="mx-1"><p style={{border: '0px solid gray'}}
                                                                     className={"p-0 " + (value.checks.indexOf(4) !== -1 ? "text-white" : "text-white-50")}>

                                                {t("base.fonctionnalites.import_export")}
                                            </p></div>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <div className="pt-1"><span
                                                className="text-info">{value.checks.indexOf(5) !== -1 ? (
                                                <i className="fa-solid fa-check"></i>) : (
                                                <span>&nbsp;&nbsp;&nbsp;</span>)}</span></div>
                                            <div className="mx-1"><p style={{border: '0px solid gray'}}
                                                                     className={"p-0 " + (value.checks.indexOf(5) !== -1 ? "text-white" : "text-white-50")}>

                                                {t("base.fonctionnalites.controle_acces")}
                                            </p></div>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <div className="pt-1"><span
                                                className="text-info">{value.checks.indexOf(6) !== -1 ? (
                                                <i className="fa-solid fa-check"></i>) : (
                                                <span>&nbsp;&nbsp;&nbsp;</span>)}</span></div>
                                            <div className="mx-1"><p style={{border: '0px solid gray'}}
                                                                     className={"p-0 " + (value.checks.indexOf(6) !== -1 ? "text-white" : "text-white-50")}>

                                                {t("base.fonctionnalites.destruction_donnees")}<sup>2</sup>
                                            </p></div>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <div className="pt-1"><span
                                                className="text-info">{value.checks.indexOf(7) !== -1 ? (
                                                <i className="fa-solid fa-check"></i>) : (
                                                <span>&nbsp;&nbsp;&nbsp;</span>)}</span></div>
                                            <div className="mx-1"><p style={{border: '0px solid gray'}}
                                                                     className={"p-0 " + (value.checks.indexOf(7) !== -1 ? "text-white" : "text-white-50")}>

                                                {t("base.fonctionnalites.journalisation_changements")}
                                            </p></div>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <div className="pt-1"><span
                                                className="text-info">{value.checks.indexOf(8) !== -1 ? (
                                                <i className="fa-solid fa-check"></i>) : (
                                                <span>&nbsp;&nbsp;&nbsp;</span>)}</span></div>
                                            <div className="mx-1"><p style={{border: '0px solid gray'}}
                                                                     className={"p-0 " + (value.checks.indexOf(8) !== -1 ? "text-white" : "text-white-50")}>

                                                {t("base.fonctionnalites.publication_changements")}
                                            </p></div>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <div className="pt-1"><span
                                                className="text-info">{value.checks.indexOf(9) !== -1 ? (
                                                <i className="fa-solid fa-check"></i>) : (
                                                <span>&nbsp;&nbsp;&nbsp;</span>)}</span></div>
                                            <div className="mx-1"><p style={{border: '0px solid gray'}}
                                                                     className={"p-0 " + (value.checks.indexOf(9) !== -1 ? "text-white" : "text-white-50")}>

                                                {t("base.fonctionnalites.synchronisation")}<sup>3</sup>
                                            </p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    {/*</Slider>*/}
                </div>
                <div className="mt-2">
                    <span className="text-1 text-white-50">
                    <sup>1</sup> {t("footNote1")}</span><br/>
                    <span className="text-1 text-white-50">
                    <sup>2</sup> {t("footNote2")}</span><br/>
                    <span className="text-1 text-white-50">
                    <sup>3</sup> {t("footNote3")}
                </span>
                </div>
            </div>
        </section>
    );
};

export default Pricing;
