import React from "react";
import whitePaperFileEn from "../documents/AperioDATA_en.pdf";
import whitePaperFileFr from "../documents/AperioDATA_fr.pdf";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

const AboutUs = ({classicHeader, darkTheme}) => {
    const {t} = useTranslation();
    return (
        <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
            <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
                {/* Heading */}
                <div className="position-relative d-flex text-center mb-5">
                    <h2
                        className={
                            "text-24  text-uppercase fw-600 w-100 mb-0 " +
                            (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
                        }
                    >
                        {t("menu_about")}
                    </h2>
                    <p
                        className={
                            "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                            (darkTheme ? "text-white" : "text-dark")
                        }
                    >
                        {t("about_know_us_more")}
                        <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto"/>
                    </p>
                </div>
                {/* Heading end*/}
                <div className="row gy-5">
                    {/* About us content start */}
                    <div className="col-lg-7 col-xl-8 text-center text-lg-start">
                        <h2
                            className={
                                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
                            }
                        >
                            {t("about_we_are")} <span className="text-primary">AperioDATA,</span> {t("about_provides")}
                        </h2>
                        <p className={darkTheme ? "text-white-50" : ""}>
                            {t("about_paragraph_1")}      </p>
                        <p className={darkTheme ? "text-white-50" : ""}>
                            {t("about_paragraph_2")}
                        </p>
                    </div>
                    {/* About us content end */}
                    {/* about us personal details start */}
                    <div className="col-lg-5 col-xl-4">
                        <div className="ps-lg-4">
                            <ul
                                className={
                                    "list-style-2 " +
                                    (darkTheme ? "list-style-light text-light" : "")
                                }
                            >
                                <li>
                                    <span className="fw-600 me-2">{t("about_name")}:</span>AperioDATA Technologies
                                </li>
                                <li>
                                    <span className="fw-600 me-2">{t("about_email")}:</span>
                                    <a href="mailto:info@aperiodata.com">info@aperiodata.com</a>
                                </li>
                                <li className="border-0">
                                    <span
                                        className="fw-600 me-2">{t("about_location")}:</span>{t("about_location_content")}
                                </li>
                            </ul>
                            {i18n.language === 'fr' ? (
                                <a
                                    href={whitePaperFileFr}
                                    download
                                    className="btn btn-primary rounded-pill"
                                >
                                    {t("about_download_white_paper")}
                                </a>
                            ) : (
                                <a
                                    href={whitePaperFileEn}
                                    download
                                    className="btn btn-primary rounded-pill"
                                >
                                    {t("about_download_white_paper")}
                                </a>
                            )}

                        </div>
                    </div>
                    {/* about me personal details end */}
                </div>
                {/* projects rewards counting start */}
                {/*<div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>10</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experience
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>250</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Happy Clients
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>650</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Projects Done
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>38</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Awards
                </p>
              </div>
            </div>
          </div>
        </div>*/}
                {/* projects rewards counting end */}
            </div>
        </section>
    );
};

export default AboutUs;
