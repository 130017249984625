import React from "react";
import Typewriter from "typewriter-effect";
import {useTranslation} from "react-i18next";

const Home = ({classicHeader, darkTheme, handleNavClick}) => {
    const {t} = useTranslation();
    return (
        <section id="home">
            <div className="hero-wrap">
                <div className="hero-mask opacity-8 bg-dark"/>

                {/* ---------------image background------------------ */}
                <div
                    className="hero-bg parallax"
                    style={{backgroundImage: 'url("images/business-people-office-having-conversation-bg.webp")'}}
                ></div>

                {/* -------------------video background---------------------- */}

                {/* <div className="player hero-bg parallax">
          <video
            src={videobg}
            autoPlay
            muted
            loop
            style={{ width: "100%", height: "100vh", objectFit: "cover" }}
          ></video>
        </div> */}

                <div className="hero-content section d-flex min-vh-100">
                    <div className="container my-auto">
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="text-7 fw-500 text-white"><span
                                    style={{textDecoration: 'underline'}}>Aperio</span><span
                                    className="text-muted">DATA</span></div>
                                <span style={{marginLeft: "-1.5rem"}}
                                      className="text-1 text-muted text-start mb-2">{t("home_from_latin")}</span>
                                <h2 className="text-14 fw-600 text-white mb-2 mb-md-3">
                                    <Typewriter
                                        options={{
                                            strings: [
                                                t("home_typewriter_1"),
                                                t("home_typewriter_2"),
                                                t("home_typewriter_3"),
                                                t("home_typewriter_4"),
                                                t("home_typewriter_5"),
                                            ],
                                            autoStart: true,
                                            loop: true,
                                        }}
                                    />
                                </h2>
                                <p className="text-5 text-light mb-4">
                                    {t("home_slogan")}
                                </p>
                                <a
                                    href="#contact"
                                    className="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleNavClick("contact");
                                    }}
                                >
                                    {t("home_get_in_touch")}
                                </a>
                            </div>
                        </div>
                    </div>
                    <a
                        href="#about"
                        className="scroll-down-arrow text-white smooth-scroll"
                        onClick={(e) => {
                            e.preventDefault();
                            handleNavClick("about");
                        }}
                    >
            <span className="animated">
              <i className="fa fa-chevron-down"/>
            </span>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Home;
