import React from "react";
import {useTranslation} from "react-i18next";

const Disclaimer = ({darkTheme}) => {
    const {t} = useTranslation();
    return (
        <div
            id="disclaimer"
            className="modal fade"
            role="dialog"
            aria-hidden="true"
        >
            <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
            >
                <div
                    className={
                        "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
                    }
                >
                    <div className="modal-header">
                        <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
                            {t("footer_disclaimer")}
                        </h5>
                        <button
                            type="button"
                            className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                    </div>
                    <div className="modal-body p-4">
                        <p>
                            {t("disclaimer_intro")}
                        </p>
                        <ul className="lh-lg">
                            <li>
                                {t("disclaimer_1")}
                            </li>
                            <li>
                                {t("disclaimer_2")}
                            </li>
                            <li>
                                {t("disclaimer_3")}
                            </li>
                            <li>
                                {t("disclaimer_4")}
                            </li>
                            <li>
                                {t("disclaimer_5")}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Disclaimer;
