import React from "react";
import {useTranslation} from "react-i18next";

const Features = ({classicHeader, darkTheme}) => {
    const {t} = useTranslation();
    // features details
    const features = [
        {
            name: t("seamless_integration_title"),
            desc: t("seamless_integration_text"),
            icon: "fas fa-key",
        },
        {
            name: t("data_governance_title"),
            desc: t("data_governance_text"),
            icon: "fas fa-key",
        },
        {
            name: t("real_time_access_title"),
            desc: t("real_time_access_text"),
            icon: "fas fa-key",
        },
        {
            name: t("scalable_architecture_title"),
            desc: t("scalable_architecture_text"),
            icon: "fas fa-key",
        },
        {
            name: t("api_interoperability_title"),
            desc: t("api_interoperability_text"),
            icon: "fas fa-key",
        },
        {
            name: t("data_vault_support_title"),
            desc: t("data_vault_support_text"),
            icon: "fas fa-key",
        },
    ];

    return (
        <section
            id="features"
            className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
        >
            <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
                {/* Heading */}
                <div className="position-relative d-flex text-center mb-5">
                    <h2
                        className={
                            "text-24  text-uppercase fw-600 w-100 mb-0 " +
                            (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
                        }
                    >
                        {t("advantages")}
                    </h2>
                    <p
                        className={
                            "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                            (darkTheme ? "text-white" : "text-dark")
                        }
                    >
                        {t("keyFeatures")}
                        <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto"/>
                    </p>
                </div>
                {/* Heading end*/}
                {/* content start */}
                <div className="row">
                    <div className="col-lg-11 mx-auto">
                        <div className="row">
                            {features.length > 0 &&
                                features.map((feature, index) => (
                                    <div className="col-md-6" key={index}>
                                        <div className="featured-box style-3 mb-5">
                                            <div
                                                className={
                                                    "featured-box-icon text-primary  shadow-sm rounded " +
                                                    (darkTheme ? "bg-dark-1" : "bg-white")
                                                }
                                            >
                                                <i className={feature.icon}/>
                                            </div>
                                            <h3 className={darkTheme ? "text-white" : ""}>
                                                {feature.name}
                                            </h3>
                                            <p
                                                className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                                            >
                                                {feature.desc}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                {/* content end */}
            </div>
        </section>
    );
};

export default Features;
