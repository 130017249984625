import React from "react";
import {useTranslation} from "react-i18next";

const Footer = ({classicHeader, darkTheme, handleNavClick}) => {
    const {t} = useTranslation();
    return (
        <footer
            id="footer"
            className={"section " + (darkTheme ? "footer-dark bg-dark-1" : "")}
        >
            <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
                <div className="row">
                    <div className="col-lg-6 text-center text-lg-start">
                        <p className="mb-3 mb-lg-0">
                            {t("footer_copyright")}
                            {" "}
                            <a
                                href="#home"
                                className="fw-500"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleNavClick("home");
                                }}
                            >
                                AperioDATA
                            </a>
                            . {t("footer_rights_reserved")}.
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <ul className="nav nav-separator justify-content-center justify-content-lg-end">
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-bs-toggle="modal"
                                    data-bs-target="#terms-policy"
                                    href="#terms-policy"
                                >
                                    {t("footer_terms_policy")}

                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-bs-toggle="modal"
                                    data-bs-target="#disclaimer"
                                    href="#disclaimer"
                                >
                                    {t("footer_disclaimer")}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
