// Core i18next library.
import i18n from "i18next";
// Bindings for React: allow components to
// re-render when language changes.
import {initReactI18next} from "react-i18next";

i18n
    // Add React bindings as a plugin.
    .use(initReactI18next)
    // Initialize the i18next instance.
    .init({
        // Config options

        // Specifies the default language (locale) used
        // when a user visits our site for the first time.
        // We use English here, but feel free to use
        // whichever locale you want.
        lng: "fr",

        // Fallback locale used when a translation is
        // missing in the active locale. Again, use your
        // preferred locale here.
        fallbackLng: "fr",

        // Enables useful output in the browser’s
        // dev console.
        debug: true,

        // Normally, we want `escapeValue: true` as it
        // ensures that i18next escapes any code in
        // translation messages, safeguarding against
        // XSS (cross-site scripting) attacks. However,
        // React does this escaping itself, so we turn
        // it off in i18next.
        interpolation: {
            escapeValue: false,
        },

        // Translation messages. Add any languages
        // you want here.
        resources: {
            // English
            en: {
                // `translation` is the default namespace.
                // More details about namespaces shortly.
                translation: {
                    back_to_top: "Back to Top",
                    about_we_are: "We are",
                    about_provides: "providing Datahub and integration solutions.",
                    switch_language: "Change language",
                    menu_home: "Home",
                    menu_about: "About",
                    menu_products: "Products",
                    menu_problems: "Key Problems",
                    menu_features: "Features",
                    menu_architecture: "Architecture",
                    menu_pricing: "Pricing",
                    menu_contact: "Contact",
                    home_get_in_touch: "Get in Touch",
                    home_typewriter_1: "Discover...",
                    home_typewriter_2: "Make Known...",
                    home_typewriter_3: "Reveal...",
                    home_typewriter_4: "Show...",
                    home_typewriter_5: "Unveil...",
                    home_slogan: "Discover Your Data",
                    home_from_latin: "from Latin: aperiō, aperīre",
                    about_know_us_more: "Know Us More",
                    about_paragraph_1: "AperioDATA is a comprehensive platform designed to simplify multi-domain data management challenges for businesses by consolidating information from diverse sources into a centralized Data Hub. Built on the Data Vault model, AperioDATA enables organizations to achieve a \"single source of truth\" for data across customer, operational, financial, and IoT domains. This integrated hub not only centralizes data but also ensures real-time access, facilitating immediate insights and streamlined cross-domain analyses.",
                    about_paragraph_2: "In addition, AperioDATA emphasizes robust data governance, with advanced security features like role-based access controls and OAuth 2.0 authentication, ensuring compliance with data privacy standards such as GDPR. The platform's scalable and modular architecture supports easy integration of new data sources, making it adaptable to evolving enterprise data needs. By offering automated change tracking and event-driven logging, AperioDATA provides a strong framework for auditability and reliable data workflows, supporting organizations in harnessing the full potential of their data in a compliant and secure manner.",
                    about_name: "Name",
                    about_email: "Email",
                    about_location: "Location",
                    about_download_white_paper: "Download White Paper",
                    about_location_content: "Quebec City, Canada",
                    contact_email_success: "Message sent successfully!",
                    contact_email_error: "Something went wrong!",
                    contact_address: "Address",
                    contact_address_content_1: "395 Rue Françoise-Constantin",
                    contact_address_content_2: "Quebec City, Quebec",
                    contact_address_content_3: "Canada, G1X 4N6",
                    contact_follow_us: "Follow Us",
                    contact_send_us_note: "Send us a note",
                    contact_tell_us_more: "Tell us more about your needs...",
                    contact_sending: "Sending...",
                    contact_send_message: "Send Message",
                    footer_copyright: "Copyright © 2025",
                    footer_rights_reserved: "All Rights Reserved",
                    footer_terms_policy: "Terms & Policy",
                    footer_disclaimer: "Copyright & Disclaimer",
                    "ourSolution": "Our Solution",
                    "solutionOverview": "Solution Overview",
                    "dataHubBrowser": "The Data Hub Browser (360° View)",
                    "angularAppDescription": "<p>AperioDATA provides an Angular application to browse the various entities in the Data Hub.</p><p>It uses the HttpRestAPI function to search and select entities from sortable lists.</p><p>Once an entity has been selected, its data and that of related entities can be accessed via a tree view.</p><p>This application is fully configurable in terms of display and navigation, making it the ideal tool for building a 360° view.</p>",
                    "httpRestAPI": "The HttpRestAPI function",
                    "httpRestAPIDescription": "<p>The HttpRestAPI function is a RESTful API allowing to query and/or update the Data Hub over HTTP.</p><p>The function supports the following request methods:</p><ul><li>The GET method to query domain objects by specifying search criteria and paging parameters,</li><li>The POST method to create domain objects,</li><li>The PUT method to create or update domain objects,</li><li>The PATCH method to update existing domain objects,</li><li>The DELETE method to delete domain objects</li></ul>",
                    "mqRestAPI": "The MQRestAPI function",
                    "mqRestAPIDescription": "<p>The MQRestAPI function is a RESTful API allowing to query and/or update the Data Hub asynchronously over a request queue.</p><p>The function supports the following request methods:</p><ul><li>The GET method to query domain objects by specifying search criteria and paging parameters,</li><li>The POST method to create domain objects,</li><li>The PUT method to create or update domain objects,</li><li>The PATCH method to update existing domain objects,</li><li>The DELETE method to delete domain objects</li></ul>",
                    "httpUploader": "The HttpUploader function",
                    "httpUploaderDescription": "<p>The HttpUploader function allows to perform batch uploads in the Data Hub using HTTP multipart requests.</p><p>This function supports the following request methods:</p><ul><li>The POST method to create domain objects,</li><li>The PUT method to create or update domain objects,</li><li>The PATCH method to update existing domain objects.</li></ul><p>This service is mainly used for data migration or initial loading.</p>",
                    "persistenceServices": "The persistence services",
                    "persistenceServicesDescription": "<p>The persistence services allow to create, retrieve, update or delete (CRUD) domain objects at the multi-domain Data Hub level.</p><p>They use domain mappings to map domain objects to Data Hub entities. Those mappings are determined automatically by introspecting the data model at start up.</p><p>Any change to the Data Hub is recorded in the change log.</p>",
                    "multiDomainDataHub": "The multi-domain Data Hub",
                    "multiDomainDataHubDescription": "<p>The multi-domain Data Hub is modeled as a Data Vault to be flexible and easily expandable.</p><p>With a Data Vault model, it is easy to add sources without having to change the structure of the Data Vault entities.</p>",
                    "eventPublisher": "The EventPublisher function",
                    "eventPublisherDescription": "<p>The EventPublisher function listens to the change log to detect and publish any change that has been performed on the Data Hub into its dedicated topic.</p><p>The event messages are then ingested and transformed before being sent to subscriber services or applications by using a message queue or by calling a service directly.</p>",
                    "intro_title": "Manage your data with AperioDATA",
                    "intro_text": "In today's data-driven world, organizations must leverage data from various domains to drive innovation and efficiency. Our product is designed to empower businesses by seamlessly managing and exploiting multi-domain Data Hubs, placing them at the core of your integration strategy. We help your organization consolidate, govern, and fully utilize data across different domains—ensuring data becomes a key enabler of growth, insight, and operational excellence.",
                    "advantages": "Advantages",
                    "keyFeatures": "Key Features",
                    "mission_title": "Our mission",
                    "mission_text": "Our mission is to provide a powerful solution that simplifies the integration and utilization of multi-domain data. By delivering a centralized platform, we help businesses unlock the full potential of their data, ensuring it is secure, accessible, and actionable across all functional areas from customer data to operational, financial, and IoT. We make it easy to integrate, analyze, and govern data across the entire organization.",
                    "seamless_integration_title": "Seamless Multi-Domain Integration",
                    "seamless_integration_text": "Our product excels at managing data from multiple domains, including customer, operational, product, financial, and external sources. It enables real-time ingestion and unified access to data from disparate systems, providing a single source of truth across the organization.",
                    "data_governance_title": "Data Governance Across Domains",
                    "data_governance_text": "Effective governance is critical when managing multi-domain data. Our platform implements role-based access control and fine-grained permissions tailored to each data domain, ensuring secure and compliant data sharing.",
                    "real_time_access_title": "Real-Time Data Access and Insights",
                    "real_time_access_text": "With our solution, you gain real-time access to consolidated data across all domains. The platform allows for the generation of cross-domain reports, dashboards, and predictive analytics, empowering business leaders with actionable insights drawn from every corner of the enterprise.",
                    "scalable_architecture_title": "Flexible and Scalable Architecture",
                    "scalable_architecture_text": "Our product is built for scalability. As your business grows and your data domains expand, our solution seamlessly adapts. Our flexible architecture ensures easy integration and growth without disruption.",
                    "api_interoperability_title": "API and Cloud Interoperability",
                    "api_interoperability_text": "Our platform supports seamless integration through standard APIs and cloud-native capabilities, allowing for interoperability with your existing systems and third-party applications. Whether you're working with ERP, CRM, financial systems, or bespoke solutions, our product ensures a smooth data flow across domains.",
                    "data_vault_support_title": "Data Vault Support",
                    "data_vault_support_text": "Data modeling is a critical step in designing a Data Hub, as it determines how data is structured, stored, and accessed. Among the various modeling techniques available, Data Vault modeling stands out as particularly suited for Data Hub environments.",
                    "what_we_solve": "What we Solve",
                    "executive_summary_title": "Executive Summary",
                    "executive_summary_text": "AperioDATA addresses specific challenges faced by businesses in managing multi-domain data. In an era where organizations need to handle large volumes of data from various domains, AperioDATA offers an integrated solution that centralizes, governs, and optimizes data management across domains such as customer, operational, financial, and IoT data. This is particularly valuable for companies striving to unlock data insights while maintaining compliance and security standards.",
                    "data_fragmentation_title": "Data Fragmentation Across Systems",
                    "data_fragmentation_problem": "Problem: Companies often face fragmented data spread across multiple systems, making it hard to unify and analyze information.",
                    "data_fragmentation_solution": "Solution: AperioDATA provides a multi-domain Data Hub that consolidates data from diverse systems into a centralized platform. This ensures a single source of truth, allowing seamless integration and unified access to all data sources.",
                    "data_governance_problem_title": "Data Governance and Security Concerns",
                    "data_governance_problem": "Problem: Handling sensitive data across domains without proper governance and security controls leads to compliance risks.",
                    "data_governance_solution": "Solution: AperioDATA implements role-based access control and fine-grained permissions, ensuring secure data sharing while adhering to compliance regulations like GDPR and HIPAA. The platform also supports OAuth 2.0 scopes, ensuring that users only access the data they are authorized to handle.",
                    "real_time_access_problem_title": "Lack of Real-Time Data",
                    "real_time_access_problem": "Problem: Many businesses struggle with delayed access to critical data, preventing timely decision-making.",
                    "real_time_access_solution": "Solution: AperioDATA delivers real-time data access, enabling instant retrieval of consolidated data across domains. The platform supports real-time analytics and reporting, empowering decision-makers with actionable insights.",
                    "scalability_title": "Scalability and Flexibility in Data Architecture",
                    "scalability_problem": "Problem: Data systems often become rigid as businesses grow, making it difficult to scale or adapt to new data sources.",
                    "scalability_solution": "Solution: AperioDATA’s architecture is flexible and scalable, based on Data Vault modeling, which allows the platform to easily expand and incorporate new data sources without structural changes.",
                    "inefficient_integration_title": "Inefficient Data Integration",
                    "inefficient_integration_problem": "Problem: Integrating data from legacy systems, ERP, CRM, or custom applications often requires complex and time-consuming processes.",
                    "inefficient_integration_solution": "Solution: The platform offers API interoperability and cloud-native integration, enabling seamless data flow between existing systems and third-party applications, enhancing operational efficiency.",
                    "data_migration_title": "Data Migration and Loading Complexity",
                    "data_migration_problem": "Problem: Migrating or uploading large data sets into new systems can be cumbersome and error-prone.",
                    "data_migration_solution": "Solution: AperioDATA features an Upload function that simplifies bulk uploads via HTTP requests. It also supports asynchronous data loading, making large-scale migrations and initial data loads more manageable.",
                    "event_tracking_title": "Change Tracking and Event Handling",
                    "event_tracking_problem": "Problem: Businesses often need to track data changes for auditing or triggering downstream processes, but manual tracking is inefficient.",
                    "event_tracking_solution": "Solution: AperioDATA includes an Event Publisher service that automatically logs changes in the Data Hub and publishes events to notify other services. This ensures full traceability and allows for automated workflows based on data changes.",
                    "conclusion_title": "Conclusion",
                    "conclusion_text": "simplifies and strengthens the management of multi-domain data for businesses by addressing key challenges such as data fragmentation, governance, real-time access, scalability, and integration. Its robust features provide organizations with the tools to harness the full potential of their data in a secure, compliant, and efficient manner.",
                    "abonnements_et_tarifs": "Subscription Plans",
                    "base": {
                        "titre": "Base",
                        "description": "Get your Data Hub up and running quickly",
                        "prix": "USD 1,500.00 tenant/month",
                        "tenant_mois": "tenant/month",
                        "notes": "(Annual subscription-automatic renewal)",
                        "taxes": "Plus, applicable taxes",
                        "fonctionnalites": {
                            "acces_api_synchrone": "Access your data using a synchronous REST API",
                            "acces_api_asynchrone": "Access your data using an asynchronous REST API",
                            "chargement_donnees": "Load data into your Data Hub",
                            "controle_acces": "Control access to your data using roles and scopes",
                            "destruction_donnees": "Purge your data when out of date (GDPR, CCPA, etc.)",
                            "naviguer_donnees": "Browse your data (360° View of your data)",
                            "import_export": "Import and export data from your Data Hub",
                            "journalisation_changements": "Log any change to your data",
                            "publication_changements": "Publish any change to your data using Azure Service Bus",
                            "synchronisation": "Synchronize your Data Warehouse with your Data Hub"
                        }
                    },
                    "vue_3600": {
                        "titre": "360° View",
                        "description": "Set up a 360° view over your Data Hub",
                        "prix": "USD 2,500.00 tenant/month",
                        "tenant_mois": "tenant/month",
                        "fonctionnalites": {
                            "acces_api_synchrone": "Access your data using a synchronous REST API",
                            "acces_api_asynchrone": "Access your data using an asynchronous REST API",
                            "chargement_donnees": "Load data into your Data Hub",
                            "controle_acces": "Control access to your data using roles and scopes",
                            "destruction_donnees": "Purge your data when out of date (GDPR, CCPA, etc.)",
                            "naviguer_donnees": "Browse your data (360° View of your data)",
                            "import_export": "Import and export data from your Data Hub",
                            "journalisation_changements": "Log any change to your data",
                            "publication_changements": "Publish any change to your data using Azure Service Bus",
                            "synchronisation": "Synchronize your Data Warehouse with your Data Hub"
                        }
                    },
                    "b2b": {
                        "titre": "B2B",
                        "description": "Exchange data with your partners",
                        "prix": "USD 2,500.00 tenant/month",
                        "tenant_mois": "tenant/month",
                        "fonctionnalites": {
                            "acces_api_synchrone": "Access your data using a synchronous REST API",
                            "acces_api_asynchrone": "Access your data using an asynchronous REST API",
                            "chargement_donnees": "Load data into your Data Hub",
                            "controle_acces": "Control access to your data using roles and scopes",
                            "destruction_donnees": "Purge your data when out of date (GDPR, CCPA, etc.)",
                            "naviguer_donnees": "Browse your data (360° View of your data)",
                            "import_export": "Import and export data from your Data Hub",
                            "journalisation_changements": "Log any change to your data",
                            "publication_changements": "Publish any change to your data using Azure Service Bus",
                            "synchronisation": "Synchronize your Data Warehouse with your Data Hub"
                        }
                    },
                    "integration": {
                        "titre": "Integration",
                        "description": "Integrate your Data Hub with your applications",
                        "prix": "USD 3,500.00 tenant/month",
                        "tenant_mois": "tenant/month",
                        "fonctionnalites": {
                            "acces_api_synchrone": "Access your data using a synchronous REST API",
                            "acces_api_asynchrone": "Access your data using an asynchronous REST API",
                            "chargement_donnees": "Load data into your Data Hub",
                            "controle_acces": "Control access to your data using roles and scopes",
                            "destruction_donnees": "Purge your data when out of date (GDPR, CCPA, etc.)",
                            "naviguer_donnees": "Browse your data (360° View of your data)",
                            "import_export": "Import and export data from your Data Hub",
                            "journalisation_changements": "Log any change to your data",
                            "publication_changements": "Publish any change to your data using Azure Service Bus",
                            "synchronisation": "Synchronize your Data Warehouse with your Data Hub"
                        }
                    },
                    "complet": {
                        "titre": "Complete",
                        "description": "Integrate your Data Hub and set up a 360° view",
                        "prix": "USD 5,500.00 tenant/month",
                        "tenant_mois": "tenant/month",
                        "fonctionnalites": {
                            "acces_api_synchrone": "Access your data using a synchronous REST API",
                            "acces_api_asynchrone": "Access your data using an asynchronous REST API",
                            "chargement_donnees": "Load data into your Data Hub",
                            "controle_acces": "Control access to your data using roles and scopes",
                            "destruction_donnees": "Purge your data when out of date (GDPR, CCPA, etc.)",
                            "naviguer_donnees": "Browse your data (360° View of your data)",
                            "import_export": "Import and export data from your Data Hub",
                            "journalisation_changements": "Log any change to your data",
                            "publication_changements": "Publish any change to your data using Azure Service Bus",
                            "synchronisation": "Synchronize your Data Warehouse with your Data Hub"
                        }
                    },
                    "footNote1": "Once your paid subscription begins, cancelation policies vary based on your status as a new customer and product and domain selections on AperioDATA. You can cancel your subscription any time.",
                    "footNote2": "In the Data Hub, data deletion is logical, which means it retains deleted objects, ensuring that data once captured is preserved. However, we have included a purge operation that allows you to destroy the data when out-of-date.",
                    "footNote3": "Your data warehouse and data hub must be based on a similar Data Vault model.",
                    "what_we_built": "What we Built",
                    "architecture_all": "All",
                    "architecture_general": "General",
                    "architecture_apis": "APIs",
                    "architecture_persistence": "Persistence",
                    "architecture_integration": "Integration",
                    "disclaimer_intro": "This section outlines the legal rights associated with the content available on this website, as well as the limitations of liability related to the use of the site and its services.",
                    "disclaimer_1": "All content on this site, including but not limited to text, graphics, logos, images, audio clips, videos, and other materials, is the property of AperioDATA or its partners and is protected by copyright and intellectual property laws. No part of this site may be reproduced, distributed, modified, displayed, or transmitted in any form without the express permission of the rights holder.",
                    "disclaimer_2": "The information provided on this site is for general informational purposes only. AperioDATA makes no representations or warranties regarding the accuracy, completeness, or validity of the information provided. Any decision made based on this information is at your own risk. AperioDATA disclaims any responsibility for any direct, indirect, special, consequential, or other damages arising from the use of this site or the inability to use it.",
                    "disclaimer_3": "AperioDATA reserves the right to modify, add, or remove any content from this site at any time without notice. Links to external sites provided on our site are for your convenience only. AperioDATA does not endorse and is not responsible for the content of these external sites.",
                    "disclaimer_4": "Trademarks, logos, and other symbols are the exclusive property of their respective owners. Unauthorized use of any trademark or other intellectual property is prohibited.",
                    "disclaimer_5": "By accessing this site, you agree to these terms of use and commit to complying with all applicable laws and regulations.",
                },
            },
            // French
            fr: {
                // `translation` is the default namespace.
                // More details about namespaces shortly.
                translation: {
                    back_to_top: "Haut de page",
                    about_we_are: "Nous sommes",
                    about_provides: "qui fournit des solutions Datahub et d'intégration.",
                    switch_language: "Changer de langue",
                    menu_home: "Accueil",
                    menu_about: "À propos",
                    menu_products: "Produits",
                    menu_problems: "Problèmes clés",
                    menu_features: "Fonctionnalités",
                    menu_architecture: "Architecture",
                    menu_pricing: "Tarifs",
                    menu_contact: "Contact",
                    home_get_in_touch: "Contactez-nous",
                    home_typewriter_1: "Découvrir...",
                    home_typewriter_2: "Faire connaître...",
                    home_typewriter_3: "Révéler...",
                    home_typewriter_4: "Afficher...",
                    home_typewriter_5: "Dévoiler...",
                    home_slogan: "Découvrez vos données",
                    home_from_latin: "du latin : aperiō, aperīre",
                    about_know_us_more: "En savoir plus sur nous",
                    about_paragraph_1: "AperioDATA est une plate-forme complète conçue pour simplifier les défis de gestion de données multi-domaines pour les entreprises en consolidant les informations provenant de diverses sources dans un hub de données centralisé. Construit sur le modèle Data Vault, AperioDATA permet aux organisations d'obtenir une \"source unique de vérité\" pour les données dans les domaines clients, opérationnels, financiers et IoT. Ce hub intégré centralise non seulement les données, mais garantit également un accès en temps réel, facilitant des informations immédiates et des analyses interdomaines rationalisées. ",
                    about_paragraph_2: "De plus, AperioDATA met l'accent sur une gouvernance robuste des données, avec des fonctionnalités de sécurité avancées telles que les contrôles d'accès basés sur les rôles et l'authentification OAuth 2.0, garantissant la conformité aux normes de confidentialité des données telles que le RGPD. L'architecture évolutive et modulaire de la plateforme prend en charge une intégration facile de nouvelles sources de données. , ce qui le rend adaptable à l'évolution des besoins en matière de données de l'entreprise. En offrant un suivi automatisé des modifications et une journalisation basée sur les événements, AperioDATA fournit un cadre solide pour l'auditabilité et des flux de données fiables, aidant les organisations à exploiter les données. tout le potentiel de leurs données de manière conforme et sécurisée.",
                    about_name: "Nom",
                    about_email: "E-mail",
                    about_location: "Emplacement",
                    about_download_white_paper: "Télécharger le livre blanc",
                    about_location_content: "Ville de Québec, Canada",
                    contact_email_success: "Message envoyé avec succès !",
                    contact_email_error: "Quelque chose s'est mal passé !",
                    contact_address: "Adresse",
                    contact_address_content_1: "395 Rue Françoise-Constantin",
                    contact_address_content_2: "Ville de Québec, Québec",
                    contact_address_content_3: "Canada, G1X 4N6",
                    contact_follow_us: "Suivez-nous",
                    contact_send_us_note: "Envoyez-nous une note",
                    contact_tell_us_more: "Parlez-nous de vos besoins...",
                    contact_sending: "Envoi...",
                    contact_send_message: "Envoyer",
                    footer_copyright: "Droits d'auteur © 2025",
                    footer_rights_reserved: "Tous droits réservés",
                    footer_terms_policy: "Conditions et politiques",
                    footer_disclaimer: "Droits d'auteur & avertissement",
                    "ourSolution": "Notre solution",
                    "solutionOverview": "Survol de la solution",
                    "dataHubBrowser": "Le navigateur du Data Hub (vue 360°)",
                    "angularAppDescription": "<p>AperioDATA fournit une application Angular pour parcourir les différentes entités du Data Hub.</p><p>Elle utilise la fonction HttpRestAPI pour rechercher et sélectionner des entités à partir de listes.</p><p>Une fois qu'une entité a été sélectionnée, ses données et celles des entités liées sont accessibles via une vue arborescente.</p><p>Cette application est entièrement configurable en termes d'affichage et de navigation, ce qui en fait l'outil idéal pour construire une vue à 360°.</p>",
                    "httpRestAPI": "La fonction HttpRestAPI",
                    "httpRestAPIDescription": "<p>La fonction HttpRestAPI est une API RESTful permettant d'interroger et/ou de mettre à jour le Data Hub via HTTP.</p><p>La fonction prend en charge les méthodes de requête suivantes :</p><ul><li>La méthode GET pour interroger les objets,</li><li>La méthode POST pour créer les objets,</li><li>La méthode PUT pour créer ou mettre à jour les objets,</li><li>La méthode PATCH pour mettre à jour les objets,</li><li>La méthode DELETE pour supprimer les objets</li></ul>",
                    "mqRestAPI": "La fonction MQRestAPI",
                    "mqRestAPIDescription": "<p>La fonction MQRestAPI est une API RESTful permettant d'interroger et/ou de mettre à jour le Data Hub de manière asynchrone via une file d'attente.</p><p>La fonction prend en charge les méthodes de requête suivantes :</p><ul><li>La méthode GET pour interroger les objets,</li><li>La méthode POST pour créer les objets,</li><li>La méthode PUT pour créer ou mettre à jour les objets,</li><li>La méthode PATCH pour mettre à jour les objets,</li><li>La méthode DELETE pour supprimer les objets</li></ul>",
                    "httpUploader": "La fonction HttpUploader",
                    "httpUploaderDescription": "<p>La fonction HttpUploader permet d'effectuer des téléchargements par lots dans le Data Hub à l'aide de requêtes multipartites HTTP.</p><p>Cette fonction prend en charge les méthodes de requête suivantes :</p><ul><li>La méthode POST pour créer les objets,</li><li>La méthode PUT pour créer ou mettre à jour les objets,</li><li>La méthode PATCH pour mettre à jour les objets.</li></ul><p>Ce service est principalement utilisé pour la migration des données ou le chargement initial.</p>",
                    "persistenceServices": "Les services de persistance",
                    "persistenceServicesDescription": "<p>Les services de persistance permettent de créer, récupérer, mettre à jour ou supprimer (CRUD) des objets de domaine au niveau du Data Hub multi-domaines.</p><p>Ils utilisent des mappages de domaine pour faire correspondre les objets de domaine aux entités du Data Hub. Ces mappages sont déterminés automatiquement par l'introspection du modèle de données au démarrage.</p><p>Toute modification apportée au Data Hub est enregistrée dans le journal des modifications.</p>",
                    "multiDomainDataHub": "Le hub de données multi-domaines",
                    "multiDomainDataHubDescription": "<p>Le Data Hub multi-domaines est modélisé comme un Data Vault pour être flexible et facilement extensible.</p><p>Avec un modèle de chambre forte, il est facile d'ajouter des sources sans avoir à modifier la structure des entités de la chambre forte.</p>",
                    "eventPublisher": "La fonction EventPublisher",
                    "eventPublisherDescription": "<p>La fonction EventPublisher écoute le journal des changements pour détecter et publier tout changement effectué sur le Data Hub.</p><p>Les messages d'événements sont ensuite ingérés et transformés avant d'être envoyés aux services ou applications abonnés en utilisant une file d'attente de messages ou en appelant directement un service.</p>",
                    "advantages": "Avantages",
                    "keyFeatures": "Principales caractéristiques",
                    "intro_title": "Gérez vos données avec AperioDATA",
                    "intro_text": "Dans le monde actuel, axé sur les données, les entreprises doivent tirer parti de données issues de multiples domaines pour stimuler l’innovation et l’efficacité. Notre produit est conçu pour permettre aux entreprises de gérer et d'exploiter leurs Data Hubs multi-domaines, les plaçant ainsi au cœur de leur stratégie d'intégration. Nous aidons votre organisation à consolider, gouverner et à tirer parti des données issues de différents domaines, garantissant ainsi que vos données deviennent un moteur essentiel de croissance, de connaissance et d’optimisation opérationnelle.",
                    "mission_title": "Notre mission",
                    "mission_text": "Notre mission est de fournir une solution qui simplifie l'intégration et l'utilisation des données multi-domaines. En offrant une plateforme centralisée, nous permettons aux entreprises de libérer tout le potentiel de leurs données, en les rendant sécurisées, accessibles et exploitables dans tous les domaines fonctionnels — des données clients aux données opérationnelles, financières et IoT. Nous facilitons l'intégration, l'analyse et la gouvernance des données dans l'ensemble de l'organisation.",
                    "seamless_integration_title": "Intégration fluide des données multi-domaines",
                    "seamless_integration_text": "Notre produit excelle dans la gestion des données provenant de multiples domaines, qu’il s’agisse de données clients, opérationnelles, produits, financières ou de sources externes. Il permet une ingestion en temps réel et un accès unifié aux données provenant de systèmes disparates, offrant ainsi une source unique de vérité pour toute l’organisation.",
                    "data_governance_title": "Gouvernance des données inter-domaines",
                    "data_governance_text": "La gouvernance efficace est cruciale pour la gestion des données multi-domaines. Notre plateforme met en œuvre des contrôles d'accès basés sur les rôles et des autorisations granulaires adaptés à chaque domaine de données, garantissant un partage de données sécurisé et conforme.",
                    "real_time_access_title": "Accès en temps réel",
                    "real_time_access_text": "Avec notre solution, vous bénéficiez d'un accès en temps réel à des données consolidées dans tous les domaines. La plateforme permet de générer des rapports inter-domaines, des tableaux de bord et des analyses prédictives, offrant aux dirigeants des aperçus clés sur les données exploitables tirés de toutes les parties de l’entreprise.",
                    "scalable_architecture_title": "Architecture flexible et évolutive",
                    "scalable_architecture_text": "Notre produit est conçu pour être scalable. À mesure que votre entreprise grandit et que vos domaines de données se développent, notre solution s'adapte sans effort. Notre architecture flexible garantit une intégration et une croissance sans interruption.",
                    "api_interoperability_title": "Interopérabilité via API et services cloud",
                    "api_interoperability_text": "Notre plateforme supporte une intégration fluide grâce à des APIs standards et des capacités cloud-natives, permettant une interopérabilité avec vos systèmes existants et des applications tierces. Que vous travailliez avec des systèmes ERP, CRM, financiers ou des solutions personnalisées, notre produit garantit un flux de données fluide entre les différents domaines.",
                    "data_vault_support_title": "Support de la modélisation Data Vault",
                    "data_vault_support_text": "La modélisation des données est une étape clé dans la conception d'un Data Hub, car elle détermine la manière dont les données sont structurées, stockées et exploitées. Parmi les différentes méthodes de modélisation disponibles, la modélisation Data Vault s'est imposée comme une approche particulièrement adaptée aux environnements de Data Hub.",
                    "what_we_solve": "Ce que nous solutionnons",
                    "executive_summary_title": "Sommaire Exécutif",
                    "executive_summary_text": "AperioDATA résout des problèmes spécifiques auxquels sont confrontées les entreprises en matière de gestion des données multi-domaines. À une époque où les organisations doivent gérer de vastes volumes de données provenant de divers domaines, AperioDATA propose une solution intégrée qui centralise, gouverne et optimise la gestion des données à travers des domaines tels que les données clients, opérationnelles, financières et IoT. Cette approche est particulièrement utile pour les entreprises cherchant à exploiter leurs données tout en respectant les normes de conformité et de sécurité.",
                    "data_fragmentation_title": "Fragmentation des données à travers les systèmes",
                    "data_fragmentation_problem": "Problème : Les entreprises rencontrent souvent des données fragmentées réparties sur plusieurs systèmes, ce qui rend difficile leur unification et leur analyse.",
                    "data_fragmentation_solution": "Solution : AperioDATA propose un Data Hub multi-domaines qui consolide les données provenant de systèmes disparates dans une plateforme centralisée. Cela garantit une source unique de vérité, permettant une intégration fluide et un accès unifié à toutes les sources de données.",
                    "data_governance_problem_title": "Gouvernance des données et préoccupations en matière de sécurité",
                    "data_governance_problem": "Problème : La gestion de données sensibles à travers différents domaines sans contrôles de gouvernance appropriés conduit à des risques de non-conformité.",
                    "data_governance_solution": "Solution : AperioDATA implémente un contrôle d'accès basé sur les rôles et des autorisations granulaires, garantissant un partage de données sécurisé et conforme aux réglementations comme le RGPD et HIPAA. De plus, l'intégration des scopes OAuth 2.0 assure que les utilisateurs n'accèdent qu'aux données pour lesquelles ils sont autorisés.",
                    "real_time_access_problem_title": "Manque d'accès en temps réel aux données",
                    "real_time_access_problem": "Problème : De nombreuses entreprises ont des difficultés à accéder rapidement aux données critiques, ce qui empêche des prises de décision rapides.",
                    "real_time_access_solution": "Solution : AperioDATA offre un accès en temps réel aux données consolidées à travers tous les domaines, permettant de générer des rapports, des tableaux de bord et des analyses en temps réel, facilitant ainsi des prises de décision éclairées.",
                    "scalability_title": "Problèmes d’évolutivité et de flexibilité dans l'architecture des données",
                    "scalability_problem": "Problème : Les systèmes de données deviennent souvent rigides à mesure que les entreprises grandissent, rendant difficile l'ajout de nouvelles sources de données.",
                    "scalability_solution": "Solution : L'architecture d'AperioDATA est flexible et évolutive, basée sur la modélisation Data Vault, qui permet à la plateforme de s'adapter et d'ajouter facilement de nouvelles sources de données sans modifications structurelles majeures.",
                    "inefficient_integration_title": "Intégration inefficace des données",
                    "inefficient_integration_problem": "Problème : Intégrer des données provenant de systèmes hérités, ERP, CRM ou d'applications personnalisées est souvent un processus complexe et chronophage.",
                    "inefficient_integration_solution": "Solution : La plateforme offre une interopérabilité via API et des capacités cloud-natives, permettant une intégration fluide avec les systèmes existants et les applications tierces, améliorant ainsi l'efficacité opérationnelle.",
                    "data_migration_title": "Complexité de la migration et du chargement des données",
                    "data_migration_problem": "Problème : La migration ou le chargement de grands ensembles de données dans de nouveaux systèmes peut être difficile et sujet à des erreurs.",
                    "data_migration_solution": "Solution : AperioDATA propose une fonction Upload qui simplifie les téléchargements en masse via des requêtes HTTP. Elle prend également en charge le chargement asynchrone des données, facilitant ainsi les migrations de grande échelle et les chargements initiaux.",
                    "event_tracking_title": "Suivi des modifications et gestion des événements",
                    "event_tracking_problem": "Problème : Les entreprises ont souvent besoin de suivre les changements de données pour des raisons d’audit ou pour déclencher des processus en aval, mais le suivi manuel est inefficace.",
                    "event_tracking_solution": "Solution : AperioDATA inclut un service Event Publisher qui enregistre automatiquement les modifications dans le Data Hub et publie des événements pour notifier d'autres services. Cela garantit une traçabilité complète et permet des workflows automatisés en fonction des changements de données.",
                    "conclusion_title": "Conclusion",
                    "conclusion_text": "simplifie et renforce la gestion des données multi-domaines pour les entreprises en répondant aux principaux défis tels que la fragmentation des données, la gouvernance, l'accès en temps réel, l’évolutivité et l'intégration. Ses fonctionnalités robustes offrent aux organisations les outils nécessaires pour exploiter pleinement le potentiel de leurs données de manière sécurisée, conforme et efficace.",
                    "abonnements_et_tarifs": "Abonnements et tarifs",
                    "base": {
                        "titre": "Base",
                        "description": "Déployez rapidement votre Data Hub",
                        "prix": "1 500,00 USD",
                        "tenant_mois": "tenant/month",
                        "notes": "(Abonnement annuel-renouvellement automatique)",
                        "taxes": "Plus, les taxes applicables",
                        "fonctionnalites": {
                            "acces_api_synchrone": "Accédez à vos données via un API REST synchrone",
                            "acces_api_asynchrone": "Accédez à vos données via un API REST asynchrone",
                            "chargement_donnees": "Chargez vos données dans votre Data Hub",
                            "controle_acces": "Contrôlez l’accès à vos données à l’aide de rôles et de scopes",
                            "destruction_donnees": "Détruisez vos données lorsque périmées (RGDP, loi 25, etc.)",
                            "naviguer_donnees": "Naviguez à travers vos données (Vue 360° de vos données)",
                            "import_export": "Importez et exportez vos données à partir de votre Data Hub",
                            "journalisation_changements": "Journalisez tout changement à vos données",
                            "publication_changements": "Publiez tout changement à vos données via Azure Service Bus",
                            "synchronisation": "Synchronisez votre entrepôt de données avec votre Data Hub"
                        }
                    },
                    "vue_3600": {
                        "titre": "Vue 360°",
                        "description": "Déployez une vue 360° de vos données",
                        "prix": "2 500,00 USD",
                        "tenant_mois": "tenant/month",
                        "fonctionnalites": {
                            "acces_api_synchrone": "Accédez à vos données via un API REST synchrone",
                            "acces_api_asynchrone": "Accédez à vos données via un API REST asynchrone",
                            "chargement_donnees": "Chargez vos données dans votre Data Hub",
                            "controle_acces": "Contrôlez l’accès à vos données à l’aide de rôles et de scopes",
                            "destruction_donnees": "Détruisez vos données lorsque périmées (RGDP, loi 25, etc.)",
                            "naviguer_donnees": "Naviguez à travers vos données (Vue 360° de vos données)",
                            "import_export": "Importez et exportez vos données à partir de votre Data Hub",
                            "journalisation_changements": "Journalisez tout changement à vos données",
                            "publication_changements": "Publiez tout changement à vos données via Azure Service Bus",
                            "synchronisation": "Synchronisez votre entrepôt de données avec votre Data Hub"
                        }
                    },
                    "b2b": {
                        "titre": "B2B",
                        "description": "Échangez des données avec vos partenaires",
                        "prix": "2 500,00 USD",
                        "tenant_mois": "tenant/month",
                        "fonctionnalites": {
                            "acces_api_synchrone": "Accédez à vos données via un API REST synchrone",
                            "acces_api_asynchrone": "Accédez à vos données via un API REST asynchrone",
                            "chargement_donnees": "Chargez vos données dans votre Data Hub",
                            "controle_acces": "Contrôlez l’accès à vos données à l’aide de rôles et de scopes",
                            "destruction_donnees": "Détruisez vos données lorsque périmées (RGDP, loi 25, etc.)",
                            "naviguer_donnees": "Naviguez à travers vos données (Vue 360° de vos données)",
                            "import_export": "Importez et exportez vos données à partir de votre Data Hub",
                            "journalisation_changements": "Journalisez tout changement à vos données",
                            "publication_changements": "Publiez tout changement à vos données via Azure Service Bus",
                            "synchronisation": "Synchronisez votre entrepôt de données avec votre Data Hub"
                        }
                    },
                    "integration": {
                        "titre": "Intégration",
                        "description": "Intégrez votre Data Hub avec vos applications",
                        "prix": "3 500,00 USD",
                        "tenant_mois": "tenant/month",
                        "fonctionnalites": {
                            "acces_api_synchrone": "Accédez à vos données via un API REST synchrone",
                            "acces_api_asynchrone": "Accédez à vos données via un API REST asynchrone",
                            "chargement_donnees": "Chargez vos données dans votre Data Hub",
                            "controle_acces": "Contrôlez l’accès à vos données à l’aide de rôles et de scopes",
                            "destruction_donnees": "Détruisez vos données lorsque périmées (RGDP, loi 25, etc.)",
                            "naviguer_donnees": "Naviguez à travers vos données (Vue 360° de vos données)",
                            "import_export": "Importez et exportez vos données à partir de votre Data Hub",
                            "journalisation_changements": "Journalisez tout changement à vos données",
                            "publication_changements": "Publiez tout changement à vos données via Azure Service Bus",
                            "synchronisation": "Synchronisez votre entrepôt de données avec votre Data Hub"
                        }
                    },
                    "complet": {
                        "titre": "Complet",
                        "description": "Intégrez votre Data Hub et déployez une vue 360°",
                        "prix": "5 500,00 USD",
                        "tenant_mois": "tenant/month",
                        "fonctionnalites": {
                            "acces_api_synchrone": "Accédez à vos données via un API REST synchrone",
                            "acces_api_asynchrone": "Accédez à vos données via un API REST asynchrone",
                            "chargement_donnees": "Chargez vos données dans votre Data Hub",
                            "controle_acces": "Contrôlez l’accès à vos données à l’aide de rôles et de scopes",
                            "destruction_donnees": "Détruisez vos données lorsque périmées (RGDP, loi 25, etc.)",
                            "naviguer_donnees": "Naviguez à travers vos données (Vue 360° de vos données)",
                            "import_export": "Importez et exportez vos données à partir de votre Data Hub",
                            "journalisation_changements": "Journalisez tout changement à vos données",
                            "publication_changements": "Publiez tout changement à vos données via Azure Service Bus",
                            "synchronisation": "Synchronisez votre entrepôt de données avec votre Data Hub"
                        }
                    },
                    "footNote1": "Une fois votre abonnement payant démarré, les politiques d’annulation varient en fonction de votre statut de nouveau client, et des sélections de produit et de domaine sur AperioDATA. Vous pouvez résilier votre abonnement en tout temps.",
                    "footNote2": "Dans le Data Hub, la suppression des données est logique, ce qui signifie qu'il conserve les objets supprimés, garantissant ainsi que les données capturées sont préservées. Cependant, nous avons inclus une opération spécifique (purge) qui vous permet de détruire les données lorsqu'elles sont périmées.",
                    "footNote3": "Votre entrepôt de données et votre Data Hub doivent être issus d’un modèle similaire voire du même modèle Data Vault.",
                    "what_we_built": "Ce que nous avons construit",
                    "architecture_all": "Toutes",
                    "architecture_general": "Général",
                    "architecture_apis": "APIs",
                    "architecture_persistence": "Persistance",
                    "architecture_integration": "Intégration",
                    "disclaimer_intro": "Cette section définit les droits légaux associés au contenu disponible sur ce site, ainsi que les limitations de responsabilité liées à l'utilisation du site et de ses services.",
                    "disclaimer_1": "Tous les contenus présents sur ce site, y compris, mais sans s'y limiter, les textes, graphiques, logos, images, clips audio, vidéos, et autres matériaux, sont la propriété d'AperioDATA ou de ses partenaires et sont protégés par les lois sur le droit d'auteur et la propriété intellectuelle. Aucune partie de ce site ne peut être reproduite, distribuée, modifiée, affichée, ou transmise sous quelque forme que ce soit sans l'autorisation expresse de l'entité titulaire des droits.",
                    "disclaimer_2": "Les informations fournies sur ce site sont destinées à des fins générales d'information uniquement. AperioDATA ne garantit pas l'exactitude, la complétude, ou la validité des informations fournies. Toute décision prise sur la base de ces informations est à vos propres risques. AperioDATA décline toute responsabilité en cas de dommages directs, indirects, spéciaux, consécutifs ou autres, découlant de l'utilisation de ce site ou de l'incapacité à l'utiliser.",
                    "disclaimer_3": "AperioDATA se réserve le droit de modifier, d'ajouter ou de supprimer tout contenu de ce site à tout moment sans préavis. Les liens vers des sites externes fournis sur notre site sont uniquement destinés à votre commodité. AperioDATA n'endosse pas, et n'est pas responsable du contenu de ces sites externes.",
                    "disclaimer_4": "Les marques de commerce, les logos, et les autres symboles sont la propriété exclusive de leurs titulaires respectifs. L'utilisation non autorisée de toute marque ou de tout autre élément protégé par des droits de propriété intellectuelle est interdite.",
                    "disclaimer_5": "En accédant à ce site, vous acceptez ces conditions d'utilisation et vous vous engagez à respecter toutes les lois et réglementations applicables.",

                },
            },
        },
    });

export default i18n;