import React, {useState} from "react";
import {Tooltip} from "./Tooltip";
import {Link} from "react-scroll";
import {useTranslation} from "react-i18next";

const ClassicHeader = ({handleNavClick}) => {
    const [stickyHeader, setStickyHeader] = useState(false);
    const [isNavModalClose, setIsNavModalClose] = useState(true);
    const {i18n} = useTranslation();
    const {t} = useTranslation();
    const [languageSwitchLabel, setLanguageSwitchLabel] = useState((localStorage.getItem("LANGUAGE") || i18n.language) === 'fr' ? 'EN' : 'FR');
    const handleLangChange = lang => {
        i18n.changeLanguage(lang);
        localStorage.setItem("LANGUAGE", lang);
        setLanguageSwitchLabel(lang === 'fr' ? 'EN' : 'FR');
    };
    if (localStorage.getItem("LANGUAGE") && localStorage.getItem("LANGUAGE") !== i18n.language) {
        handleLangChange(localStorage.getItem("LANGUAGE"));
    }
    const checkScrollTop = () => {
        let header = document.getElementsByClassName("primary-menu");

        if (header) {
            if (
                document.body.scrollTop > 180 ||
                document.documentElement.scrollTop > 180
            ) {
                setStickyHeader(true);
            } else {
                setStickyHeader(false);
            }
        }
    };

    if (typeof window !== "undefined") {
        window.addEventListener("scroll", checkScrollTop);
    }

    return (
        <header id="header" className="sticky-top-slide">
            {/* Navbar */}
            <nav
                className={
                    "primary-menu navbar navbar-expand-lg navbar-dark bg-transparent border-bottom-0 sticky-top " +
                    (stickyHeader ? "sticky-on" : "")
                }
            >
                <div className="container-fluid position-relative g-lg-4">
                    <div className="col-auto col-lg-2">
                        {/* Logo */}
                        <Link
                            smooth
                            duration={500}
                            style={{cursor: "pointer"}}
                            className="logo"
                            to="home"
                            title="AperioDATA"
                            onClick={(e) => {
                                e.preventDefault();
                                setIsNavModalClose(true);
                            }}
                        >
                            {" "}
                            {window.innerWidth > 1124 ? (
                                <img src="/images/aperiodata-logo-white-lg.png" width={250} alt="AperioDATA"/>
                            ) : (
                                <img src="/images/aperiodata-logo-white-lg.png" width={200} alt="AperioDATA"/>
                            )}
                        </Link>
                        {/* Logo End */}
                    </div>
                    <div className="col col-lg-8 navbar-accordion">
                        <button
                            onClick={(e) => {
                                setIsNavModalClose(!isNavModalClose);
                            }}
                            className={
                                isNavModalClose
                                    ? "navbar-toggler ms-auto"
                                    : "navbar-toggler ms-auto show"
                            }
                            id="navbar-toggler"
                            type="button"
                        >
                            <span/>
                            <span/>
                            <span/>
                        </button>
                        <div
                            id="header-nav"
                            className={
                                isNavModalClose
                                    ? "collapse navbar-collapse justify-content-center "
                                    : "show navbar-collapse justify-content-center"
                            }
                        >
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link
                                        smooth
                                        duration={500}
                                        style={{cursor: "pointer"}}
                                        spy
                                        activeClass="active"
                                        className="nav-link"
                                        to="home"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsNavModalClose(true);
                                        }}
                                    >
                                        {t("menu_home")}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        smooth
                                        duration={500}
                                        style={{cursor: "pointer"}}
                                        spy
                                        activeClass="active"
                                        className="nav-link"
                                        to="about"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsNavModalClose(true);
                                        }}
                                    >
                                        {t("menu_about")}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        smooth
                                        duration={500}
                                        style={{cursor: "pointer"}}
                                        spy
                                        activeClass="active"
                                        className="nav-link"
                                        to="services"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsNavModalClose(true);
                                        }}
                                    >
                                        {t("menu_products")}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        smooth
                                        duration={500}
                                        style={{cursor: "pointer"}}
                                        spy
                                        activeClass="active"
                                        className="nav-link"
                                        to="problems"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsNavModalClose(true);
                                        }}
                                    >
                                        {t("menu_problems")}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        smooth
                                        duration={500}
                                        style={{cursor: "pointer"}}
                                        spy
                                        activeClass="active"
                                        className="nav-link"
                                        to="features"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsNavModalClose(true);
                                        }}
                                    >
                                        {t("menu_features")}
                                    </Link>
                                </li>
                                {/*<li className="nav-item">
                                    <Link
                                        smooth
                                        duration={500}
                                        style={{cursor: "pointer"}}
                                        spy
                                        activeClass="active"
                                        className="nav-link"
                                        to="architecture"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsNavModalClose(true);
                                        }}
                                    >
                                        {t("menu_architecture")}
                                    </Link>
                                </li>*/}
                                <li className="nav-item">
                                    <Link
                                        smooth
                                        duration={500}
                                        style={{cursor: "pointer"}}
                                        spy
                                        activeClass="active"
                                        className="nav-link"
                                        to="pricing"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsNavModalClose(true);
                                        }}
                                    >
                                        {t("menu_pricing")}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        smooth
                                        duration={500}
                                        style={{cursor: "pointer"}}
                                        spy
                                        activeClass="active"
                                        className="nav-link"
                                        to="contact"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsNavModalClose(true);
                                        }}
                                    >
                                        {t("menu_contact")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-auto col-lg-2 d-flex justify-content-end">
                        <ul className="social-icons social-icons-light">
                            <li className="text-white">
                                <Tooltip text={t("switch_language")} placement="top">
                                    <span
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleLangChange(i18n.language === 'fr' ? 'en' : 'fr');
                                        }}
                                    >{languageSwitchLabel}</span>
                                </Tooltip>
                            </li>
                            <li className="social-icons-twitter d-none d-sm-block">
                                <Tooltip text="X" placement="top">
                                    <a
                                        href="https://www.x.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-x-twitter"/>
                                    </a>
                                </Tooltip>
                            </li>
                            <li className="social-icons-facebook d-none d-sm-block">
                                <Tooltip text="Facebook" placement="top">
                                    <a
                                        href="https://www.facebook.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-facebook-f"/>
                                    </a>
                                </Tooltip>
                            </li>
                            <li className="social-icons-dribbble d-none d-sm-block">
                                <Tooltip text="LinkedIn" placement="top">
                                    <a
                                        href="https://www.linkedin.com/company/aperiodata-technologies"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-linkedin"/>
                                    </a>
                                </Tooltip>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            {/* Navbar End */}
        </header>
    );
};

export default ClassicHeader;
