import React from "react";
import whitePaperFileEn from "../documents/AperioDATA_en.pdf";
import whitePaperFileFr from "../documents/AperioDATA_fr.pdf";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

const Problems = ({classicHeader, darkTheme}) => {
    const {t} = useTranslation();
    const educationDetails = [
        {
            yearRange: "2000 - 2004",
            title: t("data_fragmentation_title"),
            place: t("data_fragmentation_problem"),
            desc: t("data_fragmentation_solution"),
        },
        {
            yearRange: "2005 - 2008",
            title: t("data_governance_problem_title"),
            place: t("data_governance_problem"),
            desc: t("data_governance_solution"),
        },
        {
            yearRange: "2009 - 2012",
            title: t("real_time_access_problem_title"),
            place: t("real_time_access_problem"),
            desc: t("real_time_access_solution"),
        },
        {
            yearRange: "2009 - 2012",
            title: t("scalability_title"),
            place: t("scalability_problem"),
            desc: t("scalability_solution"),
        },
    ];

    const experienceDetails = [
        {
            yearRange: "2009 - 2012",
            title: t("inefficient_integration_title"),
            place: t("inefficient_integration_problem"),
            desc: t("inefficient_integration_solution"),
        },
        {
            yearRange: "2009 - 2012",
            title: t("data_migration_title"),
            place: t("data_migration_problem"),
            desc: t("data_migration_solution"),
        },
        {
            yearRange: "2009 - 2012",
            title: t("event_tracking_title"),
            place: t("event_tracking_problem"),
            desc: t("event_tracking_solution"),
        },
    ];
    /*
      const skills = [
        {
          name: "Web Design",
          percent: 65,
        },
        {
          name: "HTML/CSS",
          percent: 95,
        },
        {
          name: "JavaScript",
          percent: 80,
        },
        {
          name: "React JS",
          percent: 70,
        },
        {
          name: "Angular Js",
          percent: 60,
        },
        {
          name: "Bootstrap",
          percent: 99,
        },
      ];*/

    return (
        <section
            id="problems"
            className={"section " + (darkTheme ? "bg-dark-1" : "")}
        >
            <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
                {/* Heading */}
                <div className="position-relative d-flex text-center mb-5">
                    <h2
                        className={
                            "text-24  text-uppercase fw-600 w-100 mb-0 " +
                            (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
                        }
                    >
                        {t("menu_problems")}
                    </h2>
                    <p
                        className={
                            "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                            (darkTheme ? "text-white" : "text-dark")
                        }
                    >
                        {" "}
                        {t("what_we_solve")}
                        <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto"/>
                    </p>
                </div>
                {/* Heading end*/}
                <div className="row gx-5">
                    {/* Our Education */}
                    <div className="col-md-6">
                        <h2
                            className={
                                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
                            }
                        >
                        </h2>
                        {educationDetails.length > 0 &&
                            educationDetails.map((value, index) => (
                                <div
                                    key={index}
                                    className={
                                        "bg-white  rounded p-4 mb-4 " +
                                        (darkTheme ? "bg-dark" : "bg-white border")
                                    }
                                >
                                    {/*<p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>*/}
                                    <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                                        {value.title}
                                    </h3>
                                    <p className={darkTheme ? "text-primary" : "text-danger"}>
                                        {value.place}
                                    </p>
                                    <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                                        {value.desc}
                                    </p>
                                </div>
                            ))}
                    </div>
                    {/* Our Experience */}
                    <div className="col-md-6">
                        <h2
                            className={
                                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
                            }
                        >
                        </h2>
                        {experienceDetails.length > 0 &&
                            experienceDetails.map((value, index) => (
                                <div
                                    key={index}
                                    className={
                                        "bg-white  rounded p-4 mb-4 " +
                                        (darkTheme ? "bg-dark" : "bg-white border")
                                    }
                                >
                                    {/*<p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>*/}
                                    <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                                        {value.title}
                                    </h3>
                                    <p className={darkTheme ? "text-primary" : "text-danger"}>
                                        {value.place}
                                    </p>
                                    <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                                        {value.desc}
                                    </p>
                                </div>
                            ))}
                    </div>
                    <div>
                        <p className={"mb-2 " + (darkTheme ? "text-white-50" : "")}><span
                            className="text-primary">AperioDATA</span> {t("conclusion_text")}</p>
                    </div>
                </div>
                {/* Our Skills */}
                {/*<h2
            className={
                "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
            }
        >
          Impacts
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
              skills.map((skill, index) => (
                  <div key={index} className="col-md-6">
                    <p
                        className={
                            " fw-500 text-start mb-2 " +
                            (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>*/}
                <div className="text-center mt-5">
                    {i18n.language === 'fr' ? (
                        <a
                            className="btn btn-outline-secondary rounded-pill shadow-none"
                            href={whitePaperFileFr}
                            download
                        >
                            {t("about_download_white_paper")}
                            <span className="ms-1">
              <i className="fas fa-download"/>
            </span>
                        </a>
                    ) : (
                        <a
                            className="btn btn-outline-secondary rounded-pill shadow-none"
                            href={whitePaperFileEn}
                            download
                        >
                            {t("about_download_white_paper")}
                            <span className="ms-1">
              <i className="fas fa-download"/>
            </span>
                        </a>
                    )}

                </div>
            </div>
        </section>
    );
};

export default Problems;
