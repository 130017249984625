import React from "react";
import {useTranslation} from "react-i18next";

const TermsAndConditions = ({darkTheme}) => {
    const {t} = useTranslation();
    return (
        <div
            id="terms-policy"
            className="modal fade"
            role="dialog"
            aria-hidden="true"
        >
            <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
            >
                <div
                    className={
                        "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
                    }
                >
                    <div className="modal-header">
                        <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
                            {t("footer_terms_policy")}
                        </h5>
                        <button
                            type="button"
                            className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                    </div>
                    <div className="modal-body p-4">
                        <p>
                            Simply dummy text of the printing and typesetting industry. Lorem
                            Ipsum has been the industry's standard dummy text ever since the
                            1500s, when an unknown printer took a galley of type and scrambled
                            it to make a type specimen book. It has survived not only five
                            centuries, but also the leap into electronic typesetting,
                            remaining essentially unchanged.
                        </p>
                        <h3 className={"mb-3 mt-4 mt-4 " + (darkTheme ? "text-white" : "")}>
                            Terms of Use
                        </h3>
                        <p>
                            It has survived not only five centuries, but also the leap into
                            electronic typesetting, remaining essentially unchanged. Simply
                            dummy text of the printing and typesetting industry.
                        </p>
                        <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
                            Part I – Information AperioDATA collects and controls
                        </h5>
                        <p>
                            Lorem Ipsum has been the industry's standard dummy text ever since
                            the 1500s, when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                        </p>
                        <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
                            Part II – Information that AperioDATA processes on your behalf
                        </h5>
                        <p>
                            Lorem Ipsum has been the industry's standard dummy text ever since
                            the 1500s, when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                        </p>
                        <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
                            Part III – General
                        </h5>
                        <p>
                            It has survived not only five centuries, but also the leap into
                            electronic typesetting, remaining essentially unchanged. Lorem
                            Ipsum has been the industry's standard dummy text ever since the
                            1500s, when an unknown printer took a galley of type and scrambled
                            it to make a type specimen book.
                        </p>
                        <h3 className={"mb-3 mt-4 " + (darkTheme ? "text-white" : "")}>
                            Privacy Policy
                        </h3>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's standard dummy text
                            ever since the 1500s, when an unknown printer took a galley of
                            type and scrambled it to make a type specimen book.
                        </p>
                        <ol className="lh-lg">
                            <li>
                                Lisque persius interesset his et, in quot quidam persequeris
                                vim, ad mea essent possim iriure.
                            </li>
                            <li>
                                Quidam lisque persius interesset his et, Lisque persius
                                interesset his et, in quot quidam persequeris vim, ad mea essent
                                possim iriure.
                            </li>
                            <li>
                                In quot quidam persequeris vim, ad mea essent possim iriure.
                                Quidam lisque persius interesset his et.
                            </li>
                            <li>
                                Quidam lisque persius interesset his et, Lisque persius
                                interesset his et.
                            </li>
                            <li>
                                Interesset his et, Lisque persius interesset his et, in quot
                                quidam persequeris vim, ad mea essent possim iriure.
                            </li>
                            <li>
                                Persius interesset his et, Lisque persius interesset his et, in
                                quot quidam persequeris vim, ad mea essent possim iriure.
                            </li>
                            <li>
                                Quot quidam persequeris vim Quidam lisque persius interesset his
                                et, Lisque persius interesset his et, in quot quidam persequeris
                                vim, ad mea essent possim iriure.
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;

/*
Termes & Politique
Ces Termes et Conditions régissent votre utilisation des services d'AperioDATA. En accédant à nos services, vous acceptez de respecter ces termes. Si vous n'êtes pas d'accord avec l'une de ces conditions, vous devez immédiatement cesser d'utiliser nos services.

Conditions d'Utilisation
Acceptation des Conditions
En accédant ou en utilisant les services fournis par AperioDATA, vous acceptez de respecter et d'être lié par ces Termes et Conditions. Si vous n'êtes pas d'accord avec ces conditions, vous devez cesser d'utiliser nos services immédiatement.

Modifications des Conditions
Nous nous réservons le droit de modifier ou de mettre à jour ces Termes à tout moment. Toute modification sera effective dès la publication des nouvelles conditions sur notre site web. Nous vous encourageons à consulter régulièrement ces conditions.

Responsabilités de l'Utilisateur
Vous vous engagez à utiliser nos services de manière légale et conforme aux lois en vigueur. Vous êtes responsable du contenu que vous publiez ou partagez sur notre plateforme, et vous devez veiller à ce qu’il ne viole pas les droits d’un tiers.

Création de Compte
Pour utiliser certains services, vous devrez créer un compte. Vous vous engagez à fournir des informations précises et actuelles lors de votre inscription et à garantir la sécurité de vos informations de compte.

Activités Interdites
Vous ne devez pas utiliser nos services à des fins illégales ou en violation des conditions énoncées. Les activités interdites incluent, mais ne se limitent pas à :

Violation des droits de propriété intellectuelle
Distribution de logiciels malveillants
Comportements abusifs ou harcelants
Partie I – Informations qu’AperioDATA collecte et contrôle
AperioDATA collecte et contrôle les données personnelles que vous fournissez lorsque vous vous inscrivez ou utilisez nos services. Ces informations peuvent inclure votre nom, adresse e-mail, et vos interactions avec nos services.

1.1 Collecte de Données
Nous recueillons des informations personnelles lorsque vous vous inscrivez, vous abonnez ou interagissez avec notre site. Cela peut inclure :

Informations de contact (nom, adresse e-mail)
Données d'utilisation (historique de navigation, recherches)
Informations de paiement (le cas échéant)
1.2 Utilisation des Données
Nous utilisons ces données pour fournir nos services, améliorer votre expérience, et optimiser notre plateforme. Ces données peuvent également être utilisées à des fins administratives, telles que la facturation et le support client.

Partie II – Informations qu’AperioDATA traite pour votre compte
AperioDATA traite des données personnelles en votre nom dans le cadre de la fourniture de nos services. Cela inclut les données que vous saisissez ou qui sont générées pendant l'utilisation des services.

2.1 Activités de Traitement
Nous traitons les données pour des objectifs tels que :

Fournir une assistance client
Traiter les transactions
Envoyer des notifications relatives aux services
Garantir la sécurité et le respect des réglementations
2.2 Protection des Données
AperioDATA met en œuvre des mesures raisonnables pour protéger vos informations personnelles contre l'accès non autorisé ou la divulgation. Nous veillons également à ce que nos partenaires respectent des normes adéquates de protection des données.

Partie III – Généralités
1.1 Limitation de Responsabilité
AperioDATA ne pourra être tenu responsable de tout dommage, perte ou dépense découlant de l'utilisation de nos services, ou d'erreurs dans le contenu fourni. Vous utilisez nos services à vos propres risques.

1.2 Droit Applicable
Ces Termes sont régis par les lois de [Votre pays ou région]. Tout litige relatif à ces Termes sera soumis aux tribunaux compétents de [Lieu].

1.3 Résiliation
Nous nous réservons le droit de suspendre ou de résilier votre accès à nos services à notre discrétion, sans préavis, en cas de violation de ces Termes.

Politique de Confidentialité
AperioDATA s'engage à protéger votre vie privée et à traiter vos données personnelles de manière responsable. Cette Politique de Confidentialité explique comment nous collectons, utilisons et protégeons vos informations personnelles.

Collecte de Données
Nous collectons des données personnelles telles que votre nom, adresse e-mail, et informations de paiement lorsque vous utilisez nos services. Nous pouvons également recueillir des données sur votre utilisation de nos services via des cookies ou des technologies similaires.

Utilisation des Données
Nous utilisons vos données pour fournir et améliorer nos services, pour communiquer avec vous, et pour des raisons de sécurité. Nous pouvons également utiliser vos informations pour des communications marketing, mais vous pouvez vous désabonner de ces communications.

Partage des Données
Nous ne vendons pas vos données personnelles. Cependant, nous pouvons partager vos informations avec des partenaires de confiance pour faciliter la fourniture de nos services.

Mesures de Sécurité
Nous mettons en place des mesures de sécurité appropriées pour protéger vos données contre l'accès non autorisé, la modification ou la divulgation.

Vos Droits
Vous avez le droit d'accéder à vos données personnelles, de les corriger ou de les supprimer à tout moment. Vous pouvez également retirer votre consentement pour le traitement de vos données lorsque cela est applicable.

Modifications de ces documents
Nous nous réservons le droit de mettre à jour cette politique et ces conditions à tout moment. Nous vous informerons des changements importants, mais nous vous encourageons à consulter ces documents régulièrement.
 */

/*
Terms & Policy
These Terms and Conditions govern your use of the services provided by AperioDATA. By accessing our services, you agree to comply with these terms. If you do not agree with any of these terms, you must immediately cease using our services.

Terms of Use
Acceptance of Terms
By accessing or using the services provided by AperioDATA, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with these terms, you must cease using our services immediately.

Modifications to Terms
We reserve the right to modify or update these Terms at any time. Any changes will be effective immediately upon posting the new terms on our website. We encourage you to review these terms regularly.

User Responsibilities
You agree to use our services in a legal and compliant manner with all applicable laws. You are responsible for the content you post or share on our platform and must ensure it does not violate any third-party rights.

Account Creation
To use certain services, you will need to create an account. You agree to provide accurate and current information during the registration process and to maintain the security of your account information.

Prohibited Activities
You may not use our services for illegal purposes or in violation of these terms. Prohibited activities include, but are not limited to:

Violating intellectual property rights
Distributing malicious software
Engaging in abusive or harassing behavior
Part I – Information AperioDATA collects and controls
AperioDATA collects and controls personal data provided by you when you register or use our services. This may include your name, email address, and your interactions with our services.

1.1 Data Collection
We collect personal information when you register, subscribe, or interact with our website. This may include:

Contact information (name, email address)
Usage data (browsing history, search queries)
Payment information (if applicable)
1.2 Data Use
We use this data to provide our services, improve your experience, and optimize our platform. This data may also be used for administrative purposes, such as billing and customer support.

Part II – Information that AperioDATA processes on your behalf
AperioDATA processes personal data on your behalf in the course of providing our services. This includes data entered by you or generated while using our services.

2.1 Processing Activities
We process data for purposes such as:

Providing customer support
Processing transactions
Sending service-related notifications
Ensuring security and compliance with regulations
2.2 Data Protection
AperioDATA implements reasonable measures to protect your personal information from unauthorized access or disclosure. We also ensure that our partners comply with appropriate data protection standards.

Part III – General
1.1 Limitation of Liability
AperioDATA will not be held liable for any damage, loss, or expense arising from the use of our services, or from any errors in the content provided. You use our services at your own risk.

1.2 Governing Law
These Terms are governed by and construed in accordance with the laws of [Your Country or Region]. Any disputes related to these Terms shall be submitted to the competent courts of [Location].

1.3 Termination
We reserve the right to suspend or terminate your access to our services at our discretion, without notice, in the event of a violation of these Terms.

Privacy Policy
AperioDATA is committed to protecting your privacy and handling your personal data responsibly. This Privacy Policy explains how we collect, use, and protect your personal information.

Data Collection
We collect personal data such as your name, email address, and payment information when you use our services. We may also collect data about your use of our services through cookies or similar technologies.

Data Use
We use your data to provide and improve our services, to communicate with you, and for security purposes. We may also use your information for marketing communications, but you can opt-out of these communications.

Data Sharing
We do not sell your personal data. However, we may share your information with trusted third-party partners to facilitate the provision of our services.

Security Measures
We implement appropriate security measures to protect your data from unauthorized access, alteration, or disclosure.

Your Rights
You have the right to access, correct, or delete your personal data at any time. You may also withdraw your consent for processing where applicable.

Modifications to these documents
We reserve the right to update this policy and these terms at any time. We will inform you of any significant changes, but we encourage you to review these documents regularly.
 */
